@import url(https://fonts.googleapis.com/css?family=Patrick+Hand|Kalam);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

#root {
  height: 100%;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.message {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(231, 243, 185, 0.9) !important;
  font-weight: bold;
  line-height: 34px;
  color: #003c1f !important;
  padding: 12px !important;
  font-size: 19px;
  z-index: 99;
}

.message.message-error {
  background-color: red;
}

.cardCover {
  position: relative;
  /* padding-top:10px; */
  display: inline-block;
}

.trackIcon {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  width: 40px;
  padding: 0.4em;
  cursor: pointer;
}

.content {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.footer {
  /*flex-shrink: 0;*/
  margin: auto auto 0 auto;
  padding: 10px;
}

#footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
}

.footer {
  background: #fff;
}

.config-icon {
  min-width: 36px !important;
}

.config-key {
  padding-right: 20vw;
}

.config-key-long {
  padding-right: 20px;
}

.config-key sup,
.config-key-long sup {
  font-size: 0.5em;
  padding-left: 0.5em;
}

.config-value {
  max-width: 40vw;
}

.message {
  position: fixed;
  bottom: 0;
  background-color: #cdc0b3;
  color: #2a6292;
  padding: 4px;
  z-index: 99;
  width: 100%;
  left: 0;
}

.ambientColourCircle {
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  padding: 4px;
  margin-right: 10px;
  margin-top: 0;
  margin-left: 2px;
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.rhb-event {
  padding: 8px 10px;
  text-align: right;
}

.rhb-dropdown {
  width: 80%;
  text-align: left;
}

.rhb-count-icon {
  vertical-align: text-top;
  margin-right: 10px;
}

/*volume controls*/
.control-slider {
  width: 100%;
  max-width: 500px;
  height: 2px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.control-slider .slider-track {
  top: 11px;

  height: 2px;
  display: block;
  position: absolute;
  border-radius: 1px;
  background: rgb(249, 94, 63);
  border: 1px solid rgb(249, 94, 63);
}
.slider-track.slider-track-1 {
  background-color: #fec4b8;

  border: 1px solid #fec4b8;
}

.slider-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
}

div.slider-thumb:focus {
  outline: none;
  height: 24px;
  width: 24px;
  top: 0px;
}

.control-slider .slider-thumb {
  top: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid rgb(249, 94, 63);
}

.slider-mark {
  position: absolute;
  width: 2px;
  height: 2px;
  /* border: 2px solid rgb(0, 0, 0); */
  background-color: rgb(249, 94, 63);
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.control-slider .slider-mark {
  margin: 0px calc(12px);
  bottom: calc(50% - 6px);
  top: 11px;
  color: white;
}

.btn {
  font-size: 14px;
}

.primary-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 140px;
  padding: 12px 15px;
  color: #fff;
  font:
    900 1.25rem/1.4 castledown-regular,
    sans-serif;
  margin: 0;
  background-color: #f85e41;
  border: 0 solid transparent;
  border-radius: 12px;
  box-shadow: inset 0 -4px 0 0 rgb(0 0 0 / 25%);
  cursor: pointer;
  transition:
    background-color 0.2s ease-in-out,
    height 0.05s ease-in-out,
    -webkit-transform 0.05s ease-in-out;
  transition:
    background-color 0.2s ease-in-out,
    height 0.05s ease-in-out,
    transform 0.05s ease-in-out;
  transition:
    background-color 0.2s ease-in-out,
    height 0.05s ease-in-out,
    transform 0.05s ease-in-out,
    -webkit-transform 0.05s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
}

.blue-button {
  border: 2px #1374e0 solid;
  font-weight: 700;
  font-size: 18px;
  min-width: 140px;
  color: #1374e0;
  background: #fff;
  border-radius: 12px;
  padding: 10px 15px;
}

.btn-foot-bar {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  padding: 4px 25px;
  background: #fbf9f9;
  z-index: 2;
}

.btn-foot-bar.disabled {
  background-color: #ffffff;
  box-shadow: 0px -4px 0px rgba(0, 0, 0, 0.1);
}

.link-button {
  border: none;
  text-decoration: none;
  background: none;
}

.trackEndEventIcon {
  opacity: 0.5;
}

.langSelector {
  height: 32px;
  width: 32px;
  font-size: 14px;
  padding-top: 2px;
}

.langSelectorList {
  position: absolute;
  list-style: none;
  padding: 0;
}

.langSelectorLink {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #f85e41;
  border-radius: 50%;
  text-align: center;
  /*height: var(--height);
  width: var(--width);*/
  background-color: white;
  color: #f85e41;
}

.langSelectorLinkSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid white;
  border-radius: 50%;
  text-align: center;
  /*height: var(--height);
  width: var(--width);*/
  color: white;
}

.langSelectorLink:hover,
.langSelectorLinkSelected:hover {
  box-shadow: 0 0 0 1.5px white;
  color: black;
}

.langSelectorLink:focus,
.langSelectorLinkSelected:focus {
  outline: none;
}

.langSelectorLink:focus-visible,
.langSelectorLinkSelected:focus-visible {
  box-shadow:
    0 0 0 1.5px white,
    0 0 0 2px #1374e0;
  color: black;
}

.langSelectorText {
  display: inline-block;
  line-height: 38px;
  text-transform: uppercase;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px); /* Dunno why this is necessary. */
}

.sr-only:not(:focus-within) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.divider-row {
  margin-top: 24px;
  margin-bottom: 24px;
  border-top-style: dashed;
  border-top-color: #ccc;
  border-top-width: 6px;
}

@font-face {
  font-family: "Castledown-regular";
  src: url(/fnt/castledown-regular.otf); /* IE9 Compat Modes */
  src:
    url(/fnt/castledown-regular.otf) format("embedded-opentype"),
    /* IE6-IE8 */ url(/fnt/castledown-regular.otf) format("woff2"),
    /* Super Modern Browsers */ url(/fnt/castledown-regular.otf) format("woff"),
    /* Pretty Modern Browsers */ url(/fnt/castledown-regular.otf)
      format("opentype"),
    /* Safari, Android, iOS */ url(/fnt/castledown-regular.otf) format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Castledown-bold";
  src: url(/fnt/castledown-bold.otf); /* IE9 Compat Modes */
  src:
    url(/fnt/castledown-bold.otf) format("embedded-opentype"),
    /* IE6-IE8 */ url(/fnt/castledown-bold.otf) format("woff2"),
    /* Super Modern Browsers */ url(/fnt/castledown-bold.otf) format("woff"),
    /* Pretty Modern Browsers */ url(/fnt/castledown-bold.otf)
      format("opentype"),
    /* Safari, Android, iOS */ url(/fnt/castledown-bold.otf) format("svg"); /* Legacy iOS */
}

html,
body {
  height: 100%;
}

html {
  font-size: 16px;
  font-family: Castledown-Regular, Roboto, sans-serif;
  --yoto-red: #f85e41;
  --yoto-green: #7b9c8c;
}

body {
  margin: 0;
  padding: 0;
  font-family: Castledown-Regular, Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #003c1f;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

p {
  font-size: 16px;
}

h1 {
  font-family: Castledown-Bold, sans-serif;
  color: #f85e41;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 24px;
}

h5 {
  font-family: Castledown-Bold, sans-serif;
  color: #f85e41;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 0 4px 0;
}

.MuiPaper-root {
  padding-left: 10px;
}

@media screen and (min-width: 300px) {
  .MYO_CardButton img {
    padding-left: 0;
  }
}

@media screen and (min-width: 300px) {
  .MYO_CTA a {
    font-size: 14px !important;
  }
}

.card-cover-listing {
  width: 100%;
  box-shadow:
    0px 6.65306px 13.3061px 3.32653px rgba(0, 0, 0, 0.1),
    inset 0px 1.66327px 0px rgba(255, 255, 255, 0.5);
  -webkit-filter: drop-shadow(0px 3.32653px 1.66327px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 3.32653px 1.66327px rgba(0, 0, 0, 0.1));
  background-size: cover;
  background-position: top;
  border-radius: 6px;
}
@media screen and (min-width: 1024px) {
  .card-cover-listing {
    border-radius: 10px;
  }
  .card-cover-listing-podcast {
    border-radius: 10px;
  }
  .card-listing-add-button-link {
    border-radius: 10px;
  }
  .card-listing-add-button-text.card-listing-add-button-text {
    font-size: 14px;
  }
}

.card-cover-listing-podcast {
  width: 100%;
  box-shadow:
    0px 6.65306px 13.3061px 3.32653px rgba(0, 0, 0, 0.1),
    inset 0px 1.66327px 0px rgba(255, 255, 255, 0.5);
  -webkit-filter: drop-shadow(0px 3.32653px 1.66327px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 3.32653px 1.66327px rgba(0, 0, 0, 0.1));
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
}

.card-listing-add-button {
  width: 100%;
  box-shadow:
    0px 6.65306px 13.3061px 3.32653px rgba(0, 0, 0, 0.1),
    inset 0px 1.66327px 0px rgba(255, 255, 255, 0.5);
  -webkit-filter: drop-shadow(0px 3.32653px 1.66327px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 3.32653px 1.66327px rgba(0, 0, 0, 0.1));
  background-size: cover;
  background-position: top;
  border-radius: 6px;
}

.card-listing-add-button-link:hover,
.card-listing-add-button-text:hover {
  text-decoration: none;
}

.card-listing-add-button-text {
  font-family: Castledown-Regular, sans-serif;
  width: 100%;
  line-height: 20px;
  font-size: 14px;

  margin-top: 10px;
  margin-bottom: 16px;
  color: #1374e0;
  word-break: break-word;
}

.card-cover-editable {
  width: 100%;
  max-width: 80vw;
  box-shadow:
    0px 6.65306px 13.3061px 3.32653px rgba(0, 0, 0, 0.1),
    inset 0px 1.66327px 0px rgba(255, 255, 255, 0.5);
  -webkit-filter: drop-shadow(0px 3.32653px 1.66327px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 3.32653px 1.66327px rgba(0, 0, 0, 0.1));
  border-radius: 5px;
}

.card-div {
  border-radius: 4px;
  transition: border 0.2s ease-in-out;
}

.user-card-div {
  padding: 0px 16px 0 0;
}

.swal-ok-button {
  background-color: #f95e3f;
  border-radius: 100px;
  text-transform: uppercase;
  width: 120px;
  height: 44px;
  font-size: 16px;
  margin: 0 15px 15px 15px;
  border: none;
}

.centered-text {
}

.create-page:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 10vh;
  bottom: 10vh;
  z-index: -1;
  opacity: 0.33;
}

.card-title-text {
  font-family: Castledown-Regular, sans-serif;
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  color: #003c1f;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 44px;
  word-break: break-word;
}

.cardLib-cardTitle {
  color: #2a6292;
  overflow: hidden;
}

.cardLib {
  text-align: right;
}

.cardLib p {
  overflow: hidden;
  font-size: 0.8em;
}

.cardLibSpacer {
  width: 100%;
  height: 250px;
}

.logout {
  color: red;
}

.primaryButton {
  width: 86%;
  height: 44px;
  max-width: 320px;
  border-radius: 100px;
  border: none;
  color: #fff;
  font-size: 17px;
  text-align: center;
  line-height: 20px;
  padding: 10px 30px 10px 30px;
  font-weight: bold;
  box-shadow: 0 15px 40px 0 rgba(240, 115, 57, 0.36);
  background: #f95e3f;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 40px;
}

.textButton {
  border: none;
  background: none;
}

.textButtonLink {
  color: #3488e5;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  border: none;
  background: none;
}

.makeyourown_banner {
  width: 100%;
  height: 220px;
  background: url("/img/makeyourown_bg.jpg") no-repeat center;
}

@media screen and (min-width: 320px) {
  .makeyourown_banner {
    /*background: url("/img/makeyourown_bg@2x.jpg") center / contain; */
  }
}

@-webkit-keyframes blinker {
  to {
    opacity: 0;
  }
}

@keyframes blinker {
  to {
    opacity: 0;
  }
}

.rssUrlInput {
  margin: 1em 2em;
}

/**device config*/
.config-list.config-list {
  margin-bottom: 22px;
  position: relative;
  padding-bottom: 0px;
  padding-top: 0px;
}
.config-list li:last-child {
  border-radius: 0px 0px 8px 8px;
  padding-bottom: 20px;
}

.config-list li {
  margin: 0 16px;
  padding-left: 20px;
  padding-right: 20px;
}
.config-list:before {
  content: "";
  background-color: #fafafa;
  position: absolute;
  top: 40px;
  bottom: 0px;
  left: 16px;
  right: 16px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 1px rgba(103, 29, 15, 0.09);
}

.config-list .has-border {
  border-radius: 8px 8px 0px 0px;
  padding-top: 20px;
}

.config-subheading.config-subheading {
  text-transform: uppercase;
  padding-left: 0px;
  padding-right: 0px;
  color: #003c1f;
  font-size: 16px;
  line-height: 40px;
  font-family: "Castledown-bold";
}

.config-subheading-span.config-subheading-span {
  text-transform: lowercase;
  color: #003c1f;
  font-size: 12px;
  font-family: "Castledown-bold";
}

.config-items.config-items {
  background-color: #fafafa;
  width: auto;
}

.config-list .MuiListItemSecondaryAction-root {
  right: 28px;
  max-width: 37vw;
}

.config-value .Mui-checked {
  padding-right: 0px;
}

.config-value .PrivateSwitchBase-root-177 {
  padding-right: 0px;
  padding-left: 16px;
}

.config-value .MuiSwitch-root {
  padding-right: 2px;
  padding-left: 18px;
}

.birthday-shoutout-form input[type="number"]::-webkit-inner-spin-button,
.birthday-shoutout-form input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.daybox {
  border: 1px solid #7b9c8c;
  border: 1px solid var(--yoto-green);
  min-width: 2.3rem;
  min-height: 2.3rem;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.monthbox {
  display: inline-table;
}

.birthday-shoutout-form .MuiFormControlLabel-labelPlacementTop {
  align-items: start;
  margin: 0;
}

.birthday-shoutout-form .MuiGrid-container > .MuiGrid-container {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1024px) {
  .header-logo-desktop {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .header-logo-mobile {
    height: 33px;
    padding-top: -3px;
  }
}

@media screen and (min-width: 1024px) {
  .header-logo-desktop {
    height: 30px;
  }

  .header-logo-mobile {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
}

.header-list {
  display: block;
  list-style: none;
  width: 100%;
  margin-bottom: 0;
  margin-left: -10px;
  height: 100%;
  position: relative;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

@media screen and (min-width: 1024px) {
  .header-list {
    margin-left: 0;
  }
}

.menu-item {
  padding-top: 6px;
  float: left;
}

.header-string {
  font-family: Castledown-Regular, Roboto, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  padding-left: 24px;
  padding-top: 6px;
}

.header-string:hover {
  color: #1b1b1b;
  text-decoration: none;
}

.header-string-active {
  color: #1b1b1b;
}

@media screen and (min-width: 1024px) {
  .mobile-only {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .desktop-only {
    display: none;
  }
}

.MuiPaper-elevation4.MuiPaper-elevation4 {
  box-shadow: none;
}

.myo-title {
  margin: 32px 0 24px 0;
}

.myo-subheading {
  font-size: 16px;
  margin: 24px 0 24px 0;
}

@media screen and (min-width: 1024px) {
  .myo-subheading {
    font-size: 18px;
    margin-bottom: 56px;
  }
  .myo-title {
    margin-bottom: 24px;
  }
}

.playlist-name-block {
  margin: 0 0 24px 0;
}

.field-label {
  margin-top: 0;
  padding: 0;
  margin-bottom: 16px;
}

.artwork-upload-container {
  margin-bottom: 32px;
}

.upload-audio-container {
  margin-bottom: 22px;
}

.p-bold {
  font-size: 16px;
  font-family: Castledown-bold, sans-serif;
}

.empty-playlist {
  color: #666666;
}

.create-btn:disabled {
  background-color: #fbf9f9;
  color: #666666;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

div.playerBody {
  padding-bottom: 120px;
  height: 100%;
  font-family: Castledown-Regular, sans-serif;
}

.cardholder {
  /*  width: 224px;
  height: 436px;*/
  margin: 40px auto -40px auto;
  padding: 20px;
  border-radius: 8px;
}

.card {
  width: 200px;
  margin: 10px auto 10px;
}

.card img {
  box-shadow: 0 45px 103px -18px rgba(156, 131, 131, 0.6);
  -webkit-box-shadow: 0 45px 103px -18px rgba(156, 131, 131, 0.6);
  -moz-box-shadow: 0 45px 103px -18px rgba(156, 131, 131, 0.6);
}

.flag {
  width: 80px;
  height: 80px;
  margin: 20px auto;
}

.button {
  margin: 15px 0;
  text-align: center;
}

.card-title {
  font-size: 24px;
  color: #003c1f;
  display: inline-block;
  width: 80%;
  margin: -30px auto auto;
  text-align: center;
  padding-bottom: 5px;
}

.card-author {
  font-size: 18px;
  color: #003c1f;
  display: inline-block;
  width: 80%;
  margin: -30px auto auto;
  text-align: center;
  margin-top: 8px;
  padding-bottom: 5px;
}

.app-download-bar {
  width: 100%;
  background: #fbf9f9;
  position: -webkit-sticky; /* Safari */
  position: fixed;
  bottom: 0;
}

.app-download-bar-content {
  width: 320px;
  margin: 0 auto;
  padding-top: 10px;
}

.player-controls {
  width: 118px;
  margin: 10px auto 10px;
  position: relative;
  top: -40px;
}

.player-area {
  width: 320px;
  height: 70px;
  margin: 0 auto;
  padding-top: 10px;
  line-height: 40px;
}

.player-area span {
  color: #000;
  margin: 0 20px;
  line-height: 70px;
}

.play-btn {
  width: 62px;
  height: 68px;
  display: block;
  background: url("/img/player/play.png") no-repeat;
  margin: auto;
  cursor: pointer;
}
.pause-btn {
  width: 62px;
  height: 68px;
  display: block;
  background: url("/img/player/pause.png") no-repeat;
  margin: auto;
  cursor: pointer;
}

.hidden-btn {
  width: 62px;
  height: 68px;
  display: block;
  background: none;
  margin: auto;
}

.asset {
  float: left;
  margin-top: 20px;
}

.displayOverlayLabel {
  color: #fff;
  margin: 5px;
}

.player-track {
  text-align: left;
  cursor: pointer;
  padding: 4px;
}

