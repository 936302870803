.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

#root {
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.message {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(231, 243, 185, 0.9) !important;
  font-weight: bold;
  line-height: 34px;
  color: #003c1f !important;
  padding: 12px !important;
  font-size: 19px;
  z-index: 99;
}

.message.message-error {
  background-color: red;
}

.cardCover {
  position: relative;
  /* padding-top:10px; */
  display: inline-block;
}

.trackIcon {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  width: 40px;
  padding: 0.4em;
  cursor: pointer;
}

.content {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.footer {
  /*flex-shrink: 0;*/
  margin: auto auto 0 auto;
  padding: 10px;
}

#footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
}

.footer {
  background: #fff;
}

.config-icon {
  min-width: 36px !important;
}

.config-key {
  padding-right: 20vw;
}

.config-key-long {
  padding-right: 20px;
}

.config-key sup,
.config-key-long sup {
  font-size: 0.5em;
  padding-left: 0.5em;
}

.config-value {
  max-width: 40vw;
}

.message {
  position: fixed;
  bottom: 0;
  background-color: #cdc0b3;
  color: #2a6292;
  padding: 4px;
  z-index: 99;
  width: 100%;
  left: 0;
}

.ambientColourCircle {
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  padding: 4px;
  margin-right: 10px;
  margin-top: 0;
  margin-left: 2px;
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.rhb-event {
  padding: 8px 10px;
  text-align: right;
}

.rhb-dropdown {
  width: 80%;
  text-align: left;
}

.rhb-count-icon {
  vertical-align: text-top;
  margin-right: 10px;
}

/*volume controls*/
.control-slider {
  width: 100%;
  max-width: 500px;
  height: 2px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.control-slider .slider-track {
  top: 11px;

  height: 2px;
  display: block;
  position: absolute;
  border-radius: 1px;
  background: rgb(249, 94, 63);
  border: 1px solid rgb(249, 94, 63);
}
.slider-track.slider-track-1 {
  background-color: #fec4b8;

  border: 1px solid #fec4b8;
}

.slider-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
}

div.slider-thumb:focus {
  outline: none;
  height: 24px;
  width: 24px;
  top: 0px;
}

.control-slider .slider-thumb {
  top: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid rgb(249, 94, 63);
}

.slider-mark {
  position: absolute;
  width: 2px;
  height: 2px;
  /* border: 2px solid rgb(0, 0, 0); */
  background-color: rgb(249, 94, 63);
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.control-slider .slider-mark {
  margin: 0px calc(12px);
  bottom: calc(50% - 6px);
  top: 11px;
  color: white;
}

.btn {
  font-size: 14px;
}

.primary-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 140px;
  padding: 12px 15px;
  color: #fff;
  font:
    900 1.25rem/1.4 castledown-regular,
    sans-serif;
  margin: 0;
  background-color: #f85e41;
  border: 0 solid transparent;
  border-radius: 12px;
  box-shadow: inset 0 -4px 0 0 rgb(0 0 0 / 25%);
  cursor: pointer;
  transition:
    background-color 0.2s ease-in-out,
    height 0.05s ease-in-out,
    transform 0.05s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
}

.blue-button {
  border: 2px #1374e0 solid;
  font-weight: 700;
  font-size: 18px;
  min-width: 140px;
  color: #1374e0;
  background: #fff;
  border-radius: 12px;
  padding: 10px 15px;
}

.btn-foot-bar {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  padding: 4px 25px;
  background: #fbf9f9;
  z-index: 2;
}

.btn-foot-bar.disabled {
  background-color: #ffffff;
  box-shadow: 0px -4px 0px rgba(0, 0, 0, 0.1);
}

.link-button {
  border: none;
  text-decoration: none;
  background: none;
}

.trackEndEventIcon {
  opacity: 0.5;
}

.langSelector {
  height: 32px;
  width: 32px;
  font-size: 14px;
  padding-top: 2px;
}

.langSelectorList {
  position: absolute;
  list-style: none;
  padding: 0;
}

.langSelectorLink {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #f85e41;
  border-radius: 50%;
  text-align: center;
  /*height: var(--height);
  width: var(--width);*/
  background-color: white;
  color: #f85e41;
}

.langSelectorLinkSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid white;
  border-radius: 50%;
  text-align: center;
  /*height: var(--height);
  width: var(--width);*/
  color: white;
}

.langSelectorLink:hover,
.langSelectorLinkSelected:hover {
  box-shadow: 0 0 0 1.5px white;
  color: black;
}

.langSelectorLink:focus,
.langSelectorLinkSelected:focus {
  outline: none;
}

.langSelectorLink:focus-visible,
.langSelectorLinkSelected:focus-visible {
  box-shadow:
    0 0 0 1.5px white,
    0 0 0 2px #1374e0;
  color: black;
}

.langSelectorText {
  display: inline-block;
  line-height: 38px;
  text-transform: uppercase;
  transform: translateY(-1px); /* Dunno why this is necessary. */
}

.sr-only:not(:focus-within) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.divider-row {
  margin-top: 24px;
  margin-bottom: 24px;
  border-top-style: dashed;
  border-top-color: #ccc;
  border-top-width: 6px;
}
