/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

div.playerBody {
  padding-bottom: 120px;
  height: 100%;
  font-family: Castledown-Regular, sans-serif;
}

.cardholder {
  /*  width: 224px;
  height: 436px;*/
  margin: 40px auto -40px auto;
  padding: 20px;
  border-radius: 8px;
}

.card {
  width: 200px;
  margin: 10px auto 10px;
}

.card img {
  box-shadow: 0 45px 103px -18px rgba(156, 131, 131, 0.6);
  -webkit-box-shadow: 0 45px 103px -18px rgba(156, 131, 131, 0.6);
  -moz-box-shadow: 0 45px 103px -18px rgba(156, 131, 131, 0.6);
}

.flag {
  width: 80px;
  height: 80px;
  margin: 20px auto;
}

.button {
  margin: 15px 0;
  text-align: center;
}

.card-title {
  font-size: 24px;
  color: #003c1f;
  display: inline-block;
  width: 80%;
  margin: -30px auto auto;
  text-align: center;
  padding-bottom: 5px;
}

.card-author {
  font-size: 18px;
  color: #003c1f;
  display: inline-block;
  width: 80%;
  margin: -30px auto auto;
  text-align: center;
  margin-top: 8px;
  padding-bottom: 5px;
}

.app-download-bar {
  width: 100%;
  background: #fbf9f9;
  position: -webkit-sticky; /* Safari */
  position: fixed;
  bottom: 0;
}

.app-download-bar-content {
  width: 320px;
  margin: 0 auto;
  padding-top: 10px;
}

.player-controls {
  width: 118px;
  margin: 10px auto 10px;
  position: relative;
  top: -40px;
}

.player-area {
  width: 320px;
  height: 70px;
  margin: 0 auto;
  padding-top: 10px;
  line-height: 40px;
}

.player-area span {
  color: #000;
  margin: 0 20px;
  line-height: 70px;
}

.play-btn {
  width: 62px;
  height: 68px;
  display: block;
  background: url("/img/player/play.png") no-repeat;
  margin: auto;
  cursor: pointer;
}
.pause-btn {
  width: 62px;
  height: 68px;
  display: block;
  background: url("/img/player/pause.png") no-repeat;
  margin: auto;
  cursor: pointer;
}

.hidden-btn {
  width: 62px;
  height: 68px;
  display: block;
  background: none;
  margin: auto;
}

.asset {
  float: left;
  margin-top: 20px;
}

.displayOverlayLabel {
  color: #fff;
  margin: 5px;
}

.player-track {
  text-align: left;
  cursor: pointer;
  padding: 4px;
}
